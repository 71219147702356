import { Suspense, lazy } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
const Login = lazy(() => import('@page/Login'));
const Logout = lazy(() => import('@page/Logout'));
const MainLayout = lazy(() => import('@components/layout/MainLayout'));

const AppRoutes = () => {
  return (
    <Suspense fallback={<></>}>
      <Routes>
        <Route path="/" element={<MainLayout />} />
        <Route path="/login" element={<Login type={'A'} />} />
        <Route path="/custlogin" element={<Login type={'B'} />} />
        <Route path="/logout" element={<Logout />} />
      </Routes>
    </Suspense>
  );
};

export const Routers = () => {
  return (
    <BrowserRouter>
      <AppRoutes />
    </BrowserRouter>
  );
};
