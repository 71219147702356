import { ConfigProvider } from 'antd';
import koKR from 'antd/locale/ko_KR';
import dayjs from 'dayjs';
import ReactDOM from 'react-dom/client';
import { QueryClient, QueryClientProvider } from 'react-query';
import { RecoilRoot } from 'recoil';
import 'dayjs/locale/ko';
import App from '@/App';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false, //창이 포커스 될 때 쿼리를 다시 가져오지 않도록
      retry: 2, // 쿼리가 실패할 경우 2번 재시도
      retryDelay: (attempt) => Math.min(attempt * 1000, 2000), //1초, 2초간격으로 재시도
      staleTime: 1000 * 60 * 5, // 5분 동안 데이터는 신프라로 간주//데이터가 매우 자주 변경되거나 사용자가 항상 최신 데이터를 보기를 원한다면 staleTime을 줄이는 것을 고려
      cacheTime: 1000 * 60 * 60, // 60분 동안 캐시 유지//메모리 사용이 큰 걱정이라면 cacheTime을 줄일 수도
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

dayjs.locale('ko');

root.render(
  <ConfigProvider
    locale={koKR}
    getPopupContainer={(triggerNode) => {
      const parentNode = triggerNode?.parentNode;
      if (parentNode instanceof HTMLDivElement && parentNode !== document.body) {
        return parentNode;
      } else {
        return document.body;
      }
    }}
  >
    <QueryClientProvider client={queryClient}>
      <RecoilRoot>
        <App />
      </RecoilRoot>
    </QueryClientProvider>
  </ConfigProvider>,
);
